import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import styled from '@emotion/styled';

const CajaForm = styled.div`
    width: 70%;
    margin: 0 auto;
    padding: 2rem;
    
`;

const Formulario = styled(Form)`
    margin: 0 0 1.75rem;
    padding: 1.5rem;
    border: 1px solid #f6f6f6;
    background-color: #f6f6f6;

    @media (min-width: 900px){
        display: flex;
        flex-direction: row;
        flex-flow: row-wrap;
        align-items: center;
        justify-content: center;
    }
    @media (max-width: 568px){
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        
    }

`;

const Entrada = styled(Field)`
    height: 4.2rem;
    width: 100%;    
    
    @media (max-width: 880px){
        margin-bottom: 0.7rem;
    }
    @media (min-width: 900px){
        margin-right: 0.7rem;
    }
`;

const Boton = styled.button`
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 1rem;
    background-color: rgb(33,44,55);
    width: 100%;
    color: #ffffff;
    display: block;
    text-align: center;
    text-decoration: none;
    font-weight: 700;
    text-transform: uppercase;

    :hover{
        background-color: rgba(44, 62, 80, .85);
        cursor: pointer;
    }
`;

const subscriptionSchema = Yup.object().shape({
    fullName: Yup.string()
        .required("El nombre es requerido")
        .min(6, 'El nombre completo debe contener por lo menos 6 caracteres'),
    email: Yup.string()
        .email("Digite un email válido")
        .required("un Email es requerido"),
});


const SubscribeForm = () => {

    const enviarDatos = (values) => {

        const email = values.email;
        const fullName = values.fullName;        

        var xhr = new XMLHttpRequest();
        var url = `${process.env.HUBSPOT_URL}`
        
        var data = {
            
            "fields": [
                {
                    "name": "email",
                    "value": email
                },
                {
                    "name": "firstname",
                    "value": fullName
                }
            ],
            "context": {
                "pageUri": "www.electronatural.com",
                "pageName": "ElectroNatural"
            },
        }
        
        
        var final_data = JSON.stringify(data)
        //console.log(final_data);
        xhr.open('POST', url);
        // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4 && xhr.status === 200) {
                alert(xhr.responseText); // Returns a 200 response if the submission is successful.
            } else if (xhr.readyState === 4 && xhr.status === 403) {
                alert(xhr.responseText); // Returns a 403 error if the portal isn't allowed to post submissions.     
            } else if (xhr.readyState === 4 && xhr.status === 404) {
                alert(xhr.responseText); //Returns a 404 error if the formGuid isn't found    
            }
        }

        // Sends the request 
        xhr.send(final_data)
    }

    // <form onSubmit={(e) => { e.preventDefault(); formik.handleSubmit(e)}} className={classes.form} noValidate>
    return (

        <CajaForm>
        <h2>Suscríbete a mi lista de correos</h2>
        <Formik
            initialValues={{ fullName: '', email: '' }}
            validationSchema={subscriptionSchema}
            onSubmit={(values, actions)=> {
                // same shape as initial values
                //console.log(values);                
                enviarDatos(values);
                actions.setSubmitting(false);
                actions.resetForm({
                    values: { fullName: '', email: '' },
                });
            }}
        >
            {({ errors, touched }) => (
                
                <Formulario>                               <Entrada 
                        type="text"
                        name="fullName"
                        placeholder="Nombre"
                    />
                    {errors.fullName && touched.fullName ? (
                        <div style={{
                            color: 'red'
                        }}>{errors.fullName}</div>) : null}
                    <Entrada 
                        name="email" 
                        type="email"
                        placeholder="Email"
                    />
                    {errors.email && touched.email ? 
                        <div style={{
                            color: 'red'
                        }}>{errors.email}</div> : null}
                    <Boton type="submit">Enviar</Boton>
                </Formulario>   
            )}
        </Formik>
        </CajaForm>
    )
}

export default SubscribeForm;