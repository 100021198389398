import React from 'react';
import styled from '@emotion/styled';
import Image from 'gatsby-image';
import { Link } from "gatsby";
import urlSlug from 'url-slug';

const Boton = styled(Link)`
    margin-top: 2rem;
    padding: 1rem;
    background-color: rgb(33,44,55);
    width: 100%;
    color: #ffffff;
    display: block;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 700;

    :hover{
        background-color: rgba(44, 62, 80, .85);
        cursor: pointer;
    }
`;

const Card = styled.div`
    border: 1px solid #e1e1e1;
    img{
        max-width: 100%;
    }
`;
const Contenido = styled.div`
    padding: 2rem;
    h3{
        font-family: 'Lato', sans-serif;
        margin: 0 0 2rem 0;
        font-size: 2rem;
    }
    .resumen{
        font-size: 1.4rem;
        color: #626262;
    }
`;

const ArticuloPreview = ({articulo}) => {

    const {titulo, resumen, imagen} = articulo;

    return (  
        <Card>
            <Image 
                fluid={imagen.sharp.fluid}
            />
            <Contenido>
                <h3>{titulo}</h3>
                
                <p className="resumen">{resumen}</p>
                <Boton to={`/${urlSlug(titulo)}`}>
                    Ver Publicación
                </Boton>
            </Contenido>
        </Card>
        
    );
}
 
export default ArticuloPreview;