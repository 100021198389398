import React from 'react';
import { css } from "@emotion/react";
import styled from '@emotion/styled';
import BackgroundImage from 'gatsby-background-image';
import ReactMarkdown from 'react-markdown';

import Layout from '../components/layout';
import useInicio from '../hooks/useInicio';
import heroCSS from '../css/hero.module.css';
import Mar from '../components/mar';
//import ListadoArticulos from '../components/listadoArticulos';
import UltimosArticulos from '../components/ultimosArticulos';
import SubscribeForm from '../components/subscribeForm';

const ImagenBackground = styled(BackgroundImage)`height: 600px;`;

const Index = () => {

    const inicio = useInicio();
    const {nombre, contenido, imagen} =inicio[0]; 
    

    return ( 
        <Layout>
            <ImagenBackground
                tag="section"
                fluid={imagen.sharp.fluid}
                fadeIn="soft"
            >
                <div className={heroCSS.imagenbg}>
                    <h1 className={heroCSS.titulo}>La Energía es Natural</h1>
                    <h2 className={heroCSS.subtitulo}>Transfórmala a favor de la Humanidad</h2>
                </div>
            </ImagenBackground>
            <main>
                <div
                    css={css`
                        max-width: 800px;
                        margin: 0 auto;
                    `}
                >
                    <h1>{nombre}</h1>
                    <ReactMarkdown css={css`text-align: justify;`}
                    >{contenido}</ReactMarkdown>
                </div>
            </main>
            <Mar />
            <UltimosArticulos />
            <SubscribeForm />
        </Layout> 
        
    );
}
 
export default Index;
