import React from 'react';
import { css } from "@emotion/react";

import useArticulos from '../hooks/useArticulos';
import ArticuloPreview from './articuloPreview';
import listadoarticulosCSS from '../css/listadoArticulos.module.css';
//import useFiltro from '../hooks/useFiltro';

const UltimosArticulos = () => {

    const articulos = useArticulos();
    //const [articulos] = useState(resultado);
    
    const cantArt = 3;

    const ultimoPrimero = articulos.reverse();
    const filtrados = ultimoPrimero.slice(0,cantArt);

    //console.log(ultimoPrimero);
    //console.log(filtrados);
    

    return ( 
        <> 
        <h2
            css={css`margin-top: 5rem;`}
        >Últimas Publicaciones</h2>
        
        <ul className={listadoarticulosCSS.articulos}>
            {filtrados.map( articulo => (
                <ArticuloPreview 
                    key={articulo.id}
                    articulo={articulo}
                />
            ))}
        </ul>
        </>
    );
}
 
export default UltimosArticulos;