import { graphql, useStaticQuery } from "gatsby";

const useArticulos = () => {
    const datos = useStaticQuery(graphql`
        query{
            allStrapiArticulos{
            nodes{
                titulo
                contenido
                published_at
                resumen
                id
                autor{
                    nombre
                }
                categoria{
                    nombre
                }
                imagen{
                    sharp: childImageSharp{
                        fluid(maxWidth: 600, maxHeight: 400){
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }      
            }
            }
        }
    `);
    //console.log(datos);
    return datos.allStrapiArticulos.nodes.map(articulo => ({
        titulo: articulo.titulo,
        imagen: articulo.imagen,
        contenido: articulo.contenido,
        resumen: articulo.resumen,
        autor: articulo.autor,
        categoria: articulo.categoria,
        id: articulo.id,
        published_at: articulo.published_at
    }))
}

export default useArticulos;